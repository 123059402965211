.swipe-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  width: 100%;
  height: 100%;
}

.swipe-card__cards {
  position: relative;
  width: 100%;
  height: 100%;
}

.swipe-card__container {
  position: absolute;
  overflow: hidden;
  pointer-events: none;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  border-radius: 15px;
  gap: 10px;
  cursor: grab;
  user-select: none;
  transform: scale(0.95) translateY(30px);
  transition: all 0.5s;
}

.swipe-card__container:first-of-type {
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.2));
}

.swipe-card__container:last-of-type {
  pointer-events: auto;
  filter: drop-shadow(0px 0px 10px rgba(0, 0, 0, 0.2));
  transform: scale(1) translateY(0px) translateZ(0);
}

.swipe-card__header {
  display: flex;
}

.swipe-card__ribbons-container {
  position: absolute;
  width: 100%;
}

.swipe-card__ribbon-like,
.swipe-card__ribbon-dislike {
  position: absolute;
  opacity: 0;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 170px;
  font-weight: 700;
  font-size: 2rem;
  top: 3rem;
  z-index: 50;
  padding-block: 0.25rem;
  padding-inline: 0.5rem;
  border-radius: 0.375rem;
  transition: all 0.4s;
  box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.25);
}

.swipe-card__ribbon-like {
  left: 2rem;
  background-color: #4ade80;
  transform: rotate(-20deg);
  text-shadow: -2px 0px 1px rgba(0, 0, 0, 0.2);
}

.swipe-card__ribbon-dislike {
  right: 2rem;
  background-color: #f87171;
  transform: rotate(20deg);
  text-shadow: 2px 0px 1px rgba(0, 0, 0, 0.2);
}

.swipe-card__ribbon-like.show,
.swipe-card__ribbon-dislike.show {
  opacity: 1;
}

.swipe-card__image-container {
  position: relative;
  height: 250px;
}

.swipe-card__image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swipe-card__children {
  bottom: 0;
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  transition: all .7s ease;
  opacity: 1;
}

.swipe-card__children.hide-action-buttons {
  opacity: 0;
}

.swipe-card__action-button {
  height: 65px;
  width: 65px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 50%;
  padding: 10px;
  box-shadow: 0 0 10px rgba(71, 71, 71, 0.2);
  cursor: pointer;
}

.swipe-card__empty-state {
  width: 100%;
  height: 100%;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: scale(0.95);
  transition: all 0.5s ease;
}

.swipe-card__empty-state.swipe-card__empty-state.show-empty-state {
  transform: scale(1);
  opacity: 1;
}

@media (max-height: 750px) {
  .swipe-card__container {
    transform: scale(0.95) translateY(25px);
  }
}